<template>
  <v-container fluid pa-1>
    <v-row no-gutters>
      <v-col cols="12" md="12" lg="12" class="mr-2">
        <v-card flat tile>
          <v-card-title class="secondary">
            <span>Meters</span>
            <v-spacer></v-spacer>
            <v-text-field
              flat
              label="Search"
              prepend-inner-icon="search"
              hide-details
              solo
              v-model="searchMeter"
              clearable
              @click:clear="searchMeter = ''"
            ></v-text-field>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="green" v-on="on" @click="filterMeters('false')">
                  <v-icon dark>check_circle</v-icon>
                </v-btn>
              </template>
              <span>Filter online meters</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="red" v-on="on" @click="filterMeters('true')">
                  <v-icon dark>close</v-icon>
                </v-btn>
              </template>
              <span>Filter offline meters</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="purple" v-on="on" @click="filterMeters('')">
                  <v-icon dark>filter_none</v-icon>
                </v-btn>
              </template>
              <span>Clear filters</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="primary" v-on="on" @click="onAddMeterClick()">
                  <v-icon dark>add</v-icon>
                </v-btn>
              </template>
              <span>Add meter</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-data-table
            v-if="filteredmeters.length != 0"
              :headers="headers"
              :items="filteredmeters"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              class="elevation-0"
              hide-default-footer
              @page-count="pageCount = $event"
              single-select
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.serial_number">
                    <td class="text-right">
                      <v-icon v-if="item.isOffline == 'true'" color="red">highlight_off</v-icon>
                      <v-icon v-else color="green">check_circle_outline</v-icon>
                    </td>
                    <td class="text-center">
                      <a class="blue--text" @click="onMeterClick(item)">{{ item.name }}</a>
                    </td>
                    <td class="text-center">{{ item.serial_number }}</td>
                    <td class="text-center">{{ item.meterkind.family}}</td>
                    <td class="text-center">{{ item.meterkind.kind_name}}</td>
                    <td class="text-center">{{ item.gateway.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <div v-else>
              <p>No Meters found.</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-pagination v-if="filteredmeters.length >= 10 " class="mt-3" light v-model="page" :length="pageCount"></v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
      <router-view />
    </v-row>
  </v-container>
</template>
<script>
import { EventBus } from "@/lib/EventBus";
export default {
  name: "meters-list",
  components: {},
  data() {
    return {
      page:1,
      pageCount:0,
      itemsPerPage:10,
      dialog: false,
      searchMeter: "",
      filter:"",
      // addMeterView:false,
      headers: [
        { text: "", value: "isOffline", align: "center" },
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "name"
        },
        { text: "Serial No", value: "serial_number", align: "center" },
         { text: "Type", align: "center" },
        { text: "Model", align: "center" },
        { text: "Gateway", value: "gateway", align: "center" }
        // { text: "Actions", value: "", sortable: false, align: "center" }
        // { text: "", value: "name", sortable: false }
      ],
      meterSelected: {}
    };
  },
  computed: {
    meters() {
      return this.$store.getters["MDMStore/getterMeters"];
    },
    filteredmeters() {
      if ((this.searchMeter=="") && (this.filter == "")) return this.meters;
      else if(this.searchMeter != "") {
        return this.meters.filter(
          item =>
            item.serial_number
              .toLowerCase()
              .includes(this.searchMeter.toLowerCase()) ||
            item.name.toLowerCase().includes(this.searchMeter.toLowerCase())
        );
      }else if(this.filter != ""){
        return this.meters.filter(item => item.isOffline == this.filter)
      }
    }
  },
  beforeCreate() {},
  mounted() {
    this.$store.dispatch({
      type: "MDMStore/getMeters",
      orgId: localStorage.getItem("orgId")
    });
    this.$store.dispatch("MDMStore/getMeterKinds");
    EventBus.$on("filter-meter", (filter) => {
       this.filter  = filter;
     });
  },
  methods: {
    filterMeters(isOffline) {
      this.filter = isOffline;
      isOffline == "" ? this.searchMeter = "" : this.searchMeter = this.searchMeter;
    },
    onMeterClick(meter) {
      this.$store.commit("MDMStore/SET_SELECTED_METER", meter.serial_number);
      this.$router.push({ path: "/analytics" });
      EventBus.$emit("select-analytics-meter", meter.serial_number);
      // this.addMeterView = false;
    },
    meterKindName(id) {
      return this.$store.getters["MDMStore/getterMeterKindName"](id);
    },
    gatewayName(id) {
      return this.$store.getters["MDMStore/getterGatewayName"](id);
    },
    onAddMeterClick() {
      this.meterView = false;
      // this.addMeterView = true;
      this.$router.push("/settings/manage-devices/add-meter");
    }
  }
};
</script>
<style scoped>
</style>

  