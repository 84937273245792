var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-1":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-2",attrs:{"cols":"12","md":"12","lg":"12"}},[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"secondary"},[_c('span',[_vm._v("Meters")]),_c('v-spacer'),_c('v-text-field',{attrs:{"flat":"","label":"Search","prepend-inner-icon":"search","hide-details":"","solo":"","clearable":""},on:{"click:clear":function($event){_vm.searchMeter = ''}},model:{value:(_vm.searchMeter),callback:function ($$v) {_vm.searchMeter=$$v},expression:"searchMeter"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.filterMeters('false')}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("check_circle")])],1)]}}])},[_c('span',[_vm._v("Filter online meters")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.filterMeters('true')}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("close")])],1)]}}])},[_c('span',[_vm._v("Filter offline meters")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"purple"},on:{"click":function($event){return _vm.filterMeters('')}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("filter_none")])],1)]}}])},[_c('span',[_vm._v("Clear filters")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.onAddMeterClick()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add meter")])])],1),_c('v-card-text',[(_vm.filteredmeters.length != 0)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredmeters,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","single-select":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.serial_number},[_c('td',{staticClass:"text-right"},[(item.isOffline == 'true')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("highlight_off")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle_outline")])],1),_c('td',{staticClass:"text-center"},[_c('a',{staticClass:"blue--text",on:{"click":function($event){return _vm.onMeterClick(item)}}},[_vm._v(_vm._s(item.name))])]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.serial_number))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.meterkind.family))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.meterkind.kind_name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.gateway.name))])])}),0)]}}],null,false,996327509)}):_c('div',[_c('p',[_vm._v("No Meters found.")])])],1),_c('v-card-actions',[(_vm.filteredmeters.length >= 10 )?_c('v-pagination',{staticClass:"mt-3",attrs:{"light":"","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)],1),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }